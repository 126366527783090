<template>
<div>
    <!-- <sub-menu2 :menu="1"></sub-menu2> -->
    <scan-content :pagemode="mode"></scan-content>
    <nav-footer></nav-footer>
</div>
</template>

<script>
import SubMenu2 from "../../layouts/nav/SubMenu2";
import NavFooter from "../../layouts/nav/NavFooter";
import ScanContent from "./ScanContent";

import { SCAN_PRINT_MODE, SCAN_NORMAL_MODE } from "../components/fnc.js";

export default {
  data() {
    return {
      mode: SCAN_NORMAL_MODE
    };
  },
  components: {
    SubMenu2,
    NavFooter,
    ScanContent
  }
};
</script>
